import "./index.scss"

import CheckmarkValid from "~/images/checkmark-valid.svg"
import CheckmarkFocused from "~/images/checkmark-invalid.svg"
import contactsIcon from "~/images/address-card-regular.svg"
import envelopeIcon from "~/images/envelope-at.svg"
import firstNameIcon from "~/images/circle-user-regular.svg"
import Input from "~/components/Input"
import lastNameIcon from "~/images/person-circle.svg"
import phone from "~/images/phone.svg"

export interface SignUpValues {
  firstname: string
  lastname: string
  email: string
  phone: string
  region: string
}

export interface SignUpInputProps extends React.HTMLAttributes<HTMLDivElement> {
  values: SignUpValues
  changeValues: (newData: Record<string, string>) => void
}

export const SignUpInput = ({
  values,
  changeValues
}: SignUpInputProps): JSX.Element => {
  const trimPhone = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = event.target.value.replace(/\s/g, "")
    changeValues({ phone: event.target.value })
  }

  return (
    <div className="signup" data-testid="signup-input">
      <div className="flex flex-row gap-2 m-2">
        <img src={contactsIcon} alt="Person Icon" />
        <p>Kontaktoplysninger</p>
      </div>
      <div className="grid grid-cols-2 lg:w-1/2">
        <div>
          <Input
            id="firstName"
            name="firstName"
            type="text"
            placeholder="F"
            defaultValue={values["firstname"]}
            onChange={(event) => {
              changeValues({ ["firstname"]: event.target.value })
            }}
            className="peer placeholder-transparent"
            autoComplete="given-name"
            pattern="^[A-ZÆØÅa-zæøå ,.'\-]+$"
            required
          >
            <label htmlFor="firstName" className="floating-label">
              <img className="mx-1" src={firstNameIcon} alt="Firstname Icon" />
              Fornavn
            </label>
          </Input>
        </div>
        <div>
          <Input
            id="lastName"
            name="lastName"
            type="text"
            placeholder="L"
            defaultValue={values["lastname"]}
            onChange={(event) => {
              changeValues({ ["lastname"]: event.target.value })
            }}
            className="peer placeholder-transparent"
            autoComplete="family-name"
            pattern="^[A-ZÆØÅa-zæøå ,.'\-]+$"
            required
          >
            <label htmlFor="lastName" className="floating-label">
              <img className="mx-1" src={lastNameIcon} alt="Lastname Icon" />
              Efternavn
            </label>
          </Input>
        </div>
        <div className="col-span-2">
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="E"
            defaultValue={values["email"]}
            onChange={(event) => {
              changeValues({ ["email"]: event.target.value })
            }}
            className="peer placeholder-transparent"
            autoComplete="email"
            required
          >
            <label htmlFor="email" className="floating-label">
              <img className="mx-1" src={envelopeIcon} alt="Envelope Icon" />
              E-mail
            </label>
          </Input>
        </div>
        <div className="col-span-2">
          <Input
            id="phone"
            name="phone"
            type="tel"
            placeholder="P"
            defaultValue={values["phone"]}
            className="peer placeholder-transparent"
            autoComplete="tel-national"
            pattern="^[0-9]{8}$"
            onChange={trimPhone}
            required
          >
            <label htmlFor="phone" className="floating-label">
              <img className="mx-1" src={phone} alt="Phone Icon" />
              Telefonnummer
            </label>
          </Input>
        </div>
        <div className="col-span-2">
          <div id="Input__container" className="relative">
            <select
              name="region"
              id="region"
              className="Input"
              style={{ height: "58px", padding: "0px 20px" }}
              defaultValue={values["region"]}
              onChange={(event) => {
                changeValues({ ["region"]: event.target.value })
              }}
              required
            >
              <option value="" disabled>
                Hvilken region bor du i?
              </option>
              <option value="Region Hovedstaden">Region Hovedstaden</option>
              <option value="Region Sjælland">Region Sjælland</option>
              <option value="Region Nord">Region Nord</option>
              <option value="Region Midt">Region Midt</option>
              <option value="Region Syd">Region Syd</option>
            </select>
            <img
              src={CheckmarkValid}
              alt="checkmark-valid"
              className="Input__checkmark Input__checkmark-valid"
            />
            <img
              src={CheckmarkFocused}
              alt="checkmark-focused"
              className="Input__checkmark Input__checkmark-focused"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
