import { createContext, useState } from "react"
import { PropsWithChildren } from "react"
import { AllSurveys } from "~/const/globalStrings"

interface SurveyStatusContext {
  status: Record<string, boolean>
  completeSurvey: (surveyName: string) => void
  currentSurvey: string
}

export const SurveyContext = createContext<SurveyStatusContext | null>(null)

export type SurveyContextProviderProps = {
  surveyIterationId: string
  surveys: string[]
}

export const SurveyContextProvider = (
  props: PropsWithChildren<SurveyContextProviderProps>
): JSX.Element => {
  const initialState: Record<string, boolean> = {}
  AllSurveys[props.surveyIterationId].forEach(
    (surveyId) => (initialState[surveyId] = props.surveys.indexOf(surveyId) < 0)
  )

  const [status, setStatus] = useState<Record<string, boolean>>(initialState)
  const [currentSurvey, setcurrentSurvey] = useState<string>(props.surveys[0])

  const setNextSurveyAsCurrent = (finishedSurvey: string) => {
    const finishedIndex =
      AllSurveys[props.surveyIterationId].indexOf(finishedSurvey)
    setcurrentSurvey(AllSurveys[props.surveyIterationId][finishedIndex + 1])
  }

  const completeSurvey = (surveyName: string) => {
    setStatus((prevData) => {
      const newState: Record<string, boolean> = { ...prevData }
      newState[surveyName] = true
      return newState
    })
    setNextSurveyAsCurrent(surveyName)
  }

  return (
    <SurveyContext.Provider value={{ status, completeSurvey, currentSurvey }}>
      {props.children}
    </SurveyContext.Provider>
  )
}
