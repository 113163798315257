import classNames from "classnames"
import CheckmarkValid from "~/images/checkmark-valid.svg"
import CheckmarkFocused from "~/images/checkmark-invalid.svg"
import Variant from "../../const/variant"

import "./index.scss"

export enum InputSize {
  Small = "sm",
  Medium = "md",
  Large = "lg"
}

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  size?: InputSize
  variant?: Variant
}

export default function Input(props: InputProps): JSX.Element {
  const { size, variant, className, children, ...rest } = props

  const usedClassName = classNames(
    "Input",
    {
      [`Input--${size}`]: size,
      [`Input--${variant}`]: variant
    },
    className
  )

  return (
    <div id="Input__container" className="relative">
      <input className={usedClassName} {...rest} />
      <img
        src={CheckmarkValid}
        alt="checkmark-valid"
        className="Input__checkmark Input__checkmark-valid"
      />
      <img
        src={CheckmarkFocused}
        alt="checkmark-focused"
        className="Input__checkmark Input__checkmark-focused"
      />
      {children}
    </div>
  )
}
