import Slider from "@mui/material/Slider"
import SliderIcon from "~/images/sliderIcon.svg"
import { Data } from "~/components/SurveyComponent"
import { SliderQuestion } from "~/types/questionTypes"

type SliderInputProps = {
  data: Data
  question: SliderQuestion
  updateFields: (newData: Data) => void
}

export const SliderInput = (props: SliderInputProps): JSX.Element => {
  const {
    data,
    question,
    question: { fieldName },
    updateFields
  } = props
  const maxVal = parseInt(question.textValidationMax)

  const marks = question.selectChoicesOrCalculations
    .split("|")
    .reduce(function (result: { value: number; label: string }[], str: string) {
      const trimStr = str.trim()

      /* istanbul ignore else */
      if (!isNaN(parseInt(trimStr.charAt(0)))) {
        const splitStr = trimStr.split(" ")
        const labelSlice = splitStr.slice(1).join(" ").trim()
        result.push({ value: parseInt(splitStr[0]), label: labelSlice })
      } else if (!isNaN(parseInt(trimStr.charAt(trimStr.length - 1)))) {
        const splitStr = trimStr.split(" ")
        const labelSlice = splitStr
          .slice(0, splitStr.length - 1)
          .join(" ")
          .trim()
        result.push({
          value: parseInt(splitStr?.[splitStr.length - 1]),
          label: labelSlice
        })
      }
      return result
    }, [])

  return (
    <>
      <div className="flex flex-row gap-2 mb-10">
        <img src={SliderIcon} alt="Slider Icon" />
        <p>Træk slideren til det passende nummer</p>
      </div>
      <Slider
        className={`${data[fieldName] ? "slider-valid" : ""}`}
        aria-label="Slider"
        value={parseInt(data[fieldName]) || Math.ceil(maxVal / 2)}
        valueLabelDisplay="on"
        step={1}
        min={1}
        marks={[
          ...Array(maxVal)
            .fill(0)
            .map((_, i) => ({ value: i + 1, label: i + 1 }))
        ]}
        max={maxVal}
        onChange={(_, newValue) => {
          updateFields({ [fieldName]: String(newValue) })
        }}
      />
      <div className="slider-range-label">
        {marks.map((mark) => (
          <p key={`slider-label-${mark.value}`}>{mark.label}</p>
        ))}
      </div>
      <input
        className="invisible"
        type="number"
        name="slider-valid-input"
        value={data[fieldName] ?? ""}
        readOnly
        required
      />
    </>
  )
}
