import TimePicker from "react-time-picker"
import textIcon from "~/images/textIcon.svg"
import Input from "~/components/Input"
import { Data } from "~/components/SurveyComponent"
import { TextQuestion } from "~/types/questionTypes"
import CheckmarkValid from "~/images/checkmark-valid.svg"
import CheckmarkFocused from "~/images/checkmark-invalid.svg"
import ClockIcon from "~/images/clockIcon.svg"
import neckMeasureIcon from "~/images/neckMeasure.svg"

type TextInputProps = {
  data: Data
  question: TextQuestion
  updateFields: (newData: Data) => void
}

export const TextInput = (props: TextInputProps): JSX.Element => {
  const {
    fieldName,
    textValidationMin,
    textValidationMax,
    textValidationTypeOrShowSliderNumber,
    fieldLabel,
    fieldNote
  } = props.question
  const { data, updateFields } = props
  data[fieldName] = data[fieldName] || ""

  const minMaxProps = {
    ...(textValidationMin && { min: textValidationMin }),
    ...(textValidationMax && { max: textValidationMax })
  }

  const inputType = textValidationTypeOrShowSliderNumber
    ? textValidationTypeOrShowSliderNumber
    : "text"

  return textValidationTypeOrShowSliderNumber === "time" ? (
    <>
      <div className="flex flex-row gap-2 mb-2">
        <img src={ClockIcon} alt="Time Icon" />
        <p>Vælg tidspunkt</p>
      </div>
      <div className="relative">
        <TimePicker
          required
          autoFocus
          className="timepicker"
          name={fieldName}
          value={data[fieldName]}
          onChange={(newValue) =>
            newValue && updateFields({ [fieldName]: String(newValue) })
          }
          hourPlaceholder="HH"
          minutePlaceholder="MM"
          format="HH:mm"
          disableClock
          clearIcon={null}
        />
        <img
          src={CheckmarkFocused}
          alt="checkmark-focused"
          className="timepicker__checkmark timepicker__checkmark-focused"
        />
        <img
          src={CheckmarkValid}
          alt="checkmark-valid"
          className="timepicker__checkmark timepicker__checkmark-valid"
        />
      </div>
    </>
  ) : (
    <>
      {
        <div className="flex flex-row gap-2 mb-2">
          <img src={textIcon} alt="Text Icon" />
          <p>{fieldNote ? fieldNote : "Skriv dit svar"}</p>
        </div>
      }
      <Input
        autoFocus
        required
        type={inputType}
        name={fieldName}
        value={data[fieldName]}
        onChange={(event) => {
          updateFields({ [fieldName]: event.target.value })
        }}
        {...minMaxProps}
        {...(textValidationTypeOrShowSliderNumber === "integer" && {
          type: "number",
          step: "1"
        })}
      />
      {fieldLabel === "Halsomkreds" && (
        <div className="w-full flex flex-col gap-2 mt-4">
          <img
            src={neckMeasureIcon}
            alt="Neck Measure Image"
            className="w-36"
          />
          <p>
            Mål omkredsen af din hals med et målebånd. Du kan også bruge en
            snor, som du måler med en tommestok eller lineal efterfølgende.
          </p>
        </div>
      )}
    </>
  )
}
