import { Data } from "~/components/SurveyComponent"
import { Question } from "~/types/questionTypes"

function todaysDateAsStr(): string {
  const date = new Date()
  let month = "" + (date.getMonth() + 1)
  let day = "" + date.getDate()
  const year = date.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return `${year}-${month}-${day}`
}

export const filterAndFillDateQuestions = (
  questions: Question[]
): [Question[], Data] => {
  const excludeDates = (q: Question) =>
    !(q.fieldType === "text" && q.fieldLabel.toLowerCase() === "date")
  const includeDates = (q: Question) =>
    q.fieldType === "text" && q.fieldLabel.toLowerCase() === "date"
  const newData: Data = {}
  questions
    .filter(includeDates)
    .forEach((q) => (newData[q.fieldName] = todaysDateAsStr()))
  return [questions.filter(excludeDates), newData]
}
