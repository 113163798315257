/* eslint-disable camelcase */
export const AllSurveys: Record<string, string[]> = {
  survey_one: [
    "person_information",
    "afeqt_sprgeskema_til_undersgelse_af_effekten_af_at",
    "afss_atrial_fibrillation_severity_scale",
    "psqi_pittsburgh_sleep_quality_index",
    "ehlq_ehealth_literacy_questionnaire"
  ],
  survey_two: [
    "afeqt_sprgeskema_til_undersgelse_af_effekten_af_at",
    "afss_atrial_fibrillation_severity_scale",
    "psqi_pittsburgh_sleep_quality_index"
  ],
  survey_three: [
    "afeqt_sprgeskema_til_undersgelse_af_effekten_af_at",
    "afss_atrial_fibrillation_severity_scale",
    "psqi_pittsburgh_sleep_quality_index"
  ]
}

export const LandingPageText: Record<string, Record<string, string>> = {
  survey_one: {
    text_1:
      "Velkommen til projektet, der undersøger for søvnapnø hos personer med atrieflimren (VIR-SAAF).<br /><br />Det er vigtigt for os, at vi får viden om, hvordan du har det, samt hvordan du selv bedømmer dit helbred og symptomer.<br /><br />Du skal i alt besvare 5 spørgeskemaer ved starten af projektet, hvoraf 3 af disse besvares igen ved projektets afslutning.<br /><br />Nedenunder kan du se en oversigt over de forskellige spørgeskemaer:",
    text_2:
      "Det tager ca. 45 minutter at besvare, så du skal sætte god tid af. Vi beder dig svare på spørgeskemaet inden for tre dage. <br /><br />Vi håber, at du vil tage dig tid til at svare på alle spørgeskemaer, da dette er en vigtig del af projektet.<br /><br />God fornøjelse!"
  },
  survey_two: {
    text_1:
      "Du skal nu svare på et spørgeskema magen til det, du besvarede i starten af projektet.<br /><br />Du skal i alt besvare 3 spørgeskemaer denne gang.<br /><br />Nedenunder kan du se en oversigt over de forskellige spørgeskemaer:",
    text_2:
      "Det tager ca. 30 minutter at besvare, så du skal sætte god tid af. Vi beder dig svare på spørgeskemaet inden for tre dage.<br /><br />Vi håber, at du vil tage dig tid til at svare på alle spørgeskemaer, da dette er en vigtig del af projektet.<br /><br />Efterfølgende vil du blive inviteret til et sidste videomøde, hvor vi vil gennemgå dine resultater.<br /><br />God fornøjelse!"
  },
  survey_three: {
    text_1:
      "Tak fordi du har gennemført alle målinger!<br /><br />Du skal nu svare på et spørgeskema magen til det, du besvarede i starten af projektet.<br /><br />Du skal i alt besvare 3 spørgeskemaer denne gang.<br /><br />Nedenunder kan du se en oversigt over de forskellige spørgeskemaer:",
    text_2:
      "Det tager ca. 30 minutter at besvare, så du skal sætte god tid af. Vi beder dig svare på spørgeskemaet inden for tre dage.<br /><br />Vi håber, at du vil tage dig tid til at svare på alle spørgeskemaer, da dette er en vigtig del af projektet.<br /><br />God fornøjelse!"
  }
}

export const Explainers: Record<string, string> = {
  person_information:
    "Det første spørgeskema omhandler 6 spørgsmål omkring dig, herunder din e-mail, adresse, arbejdsstatus, højde, vægt og halsomkreds.<br /><br />Du vil blive guidet igennem alle spørgsmålene i spørgeskemaet for at gøre det så nemt som muligt for dig at besvare dem",
  afeqt_sprgeskema_til_undersgelse_af_effekten_af_at:
    "Atrial Fibrillation Effect on QualiTy-of-life (AFEQT) er et spørgeskema udviklet specifikt til personer med atrieflimren.<br /><br />Spørgeskemaet er udviklet til at bedømme, hvor meget atrieflimren og behandlingen af atrieflimren påvirker din hverdag målt på dine symptomer, funktion, daglige aktiviteter og din generelle hverdag.",
  afss_atrial_fibrillation_severity_scale:
    "Atrial Fibrillation Severity Scale (AFSS) er også et specifikt spørgeskema til personer med atrieflimren.<br /><br />Spørgeskemaet omhandler en vurdering af graden af atrieflimren-relateret symptomer samt hyppigheden og længden af dine atrieflimren-episoder.",
  psqi_pittsburgh_sleep_quality_index:
    "De følgende spørgsmål handler om dine normale søvnvaner, men udelukkende inden for den seneste måned (de seneste 30 dage). Dine svar skal, så præcist som muligt afspejle, hvordan din søvn har været for hovedparten af dagene og nætterne inden for den seneste måned.<br /><br />Besvar alle spørgsmålene.",
  ehlq_ehealth_literacy_questionnaire:
    'På de følgende sider stiller vi dig 25 korte spørgsmål vedrørende dine meninger om og nerfaringer med sundhedsvæsenet og anvendelsen af digitale tilbud og teknologi. For de fleste vil det tage ca. 10 min at udfylde skemaet.<br /><br />Der er ingen rigtige eller forkerte svar. Besvar spørgsmålene ud fra dine erfaringer og tænk på, hvad du gør, eller vil gøre for at styrke din sundhed og tage vare på dit helbred.<br /><br />Det er vigtigt at alle udsagn bliver besvaret, så hvis der er et udsagn som du ikke kan forholde dig til umiddelbart, så forsøg alligevel at give din mening tilkende.<br /><br />Lidt om nogle af de ord vi bruger i skemaet:<br /><br />Ordet <i>"sundhedsprofessionelle"</i> dækker over de personer du møder i sundhedsvæsenet, fx hos din egen læge, på hospitalet eller i kommunen. Det kan fx være læger, sygeplejersker, tandlæger, diætister, fysioterapeuter, sundhedsplejersker eller psykologer.<br /><br />Med <i>"sundhedstilbud"</i> menes de tjenester og ydelser, du får tilbudt af både sundhedsprofessionelle, og de personer, du har kontakt til udenfor det autoriserede sundhedsvæsen, fx trænere, coaches og alternative behandlere.<br /><br />Betegnelsen <i>"digitale sundhedssystemer"</i> dækker over internetsider, registre og andre kilder til helbredsoplysninger som kan tilgås digitalt, fx elektronisk patientjournal, sundhedsportaler eller din egen læges services.<br /><br />Ordet <i>"teknologi"</i> dækker over digitale sundhedssystemer og tilbud, samt alle de enheder der kan bruges til at vise, registrere eller håndtere information digitalt, fx din mobiltelefon, computer, løbe-ur eller digitale vægt.'
}

export const HeadersByFieldType: Record<string, string> = {
  text: "Spørgsmål",
  radio: "Spørgsmål",
  descriptive: "Tema",
  notes: "Spørgsmål",
  slider: "Spørgsmål",
  explainer: "",
  signup: "Tilmeldingsformular"
}

type Estimate = {
  name: string
  estimatedTimeToComplete: number
}

export const SurveyCompletionEstimates: Record<string, Estimate> = {
  person_information: {
    name: "Personlig information",
    estimatedTimeToComplete: 5
  },
  afeqt_sprgeskema_til_undersgelse_af_effekten_af_at: {
    name: "Effekten af atrieflimren på helbredsstatus",
    estimatedTimeToComplete: 10
  },
  afss_atrial_fibrillation_severity_scale: {
    name: "Atrieflimren Symptomskala",
    estimatedTimeToComplete: 10
  },
  psqi_pittsburgh_sleep_quality_index: {
    name: "Søvnkvalitet",
    estimatedTimeToComplete: 10
  },
  ehlq_ehealth_literacy_questionnaire: {
    name: "Digitale sundhedskompetencer",
    estimatedTimeToComplete: 10
  }
}

export const IndexPageText: Record<string, Record<string, string>> = {
  about: {
    header: "VIR-SAAF studiet",
    subheader:
      "Søvnapnø hos patienter med forkammerflimren - et virtuelt studie",
    bulk: "Velkommen til VIR-SAAF studiet. Den danske titel er <b>Decentralt nationalt studie med hjemme søvnapnø-undersøgelse hos patienter med atrieflimren</b> og kommer til at være det første større kardiovaskulære virtuelle studie i Danmark. Du kan læse mere om det fremtidige VIR-SAAF studie til højre eller se videoen omkring et igangværende pilot projekt Mini VIR-SAAF for at få en fornemmelse af hvad det vil sige at deltage. Teamet bag VIR-SAAF arbejder hårdt på at få udviklet og testet platformen til studiet for at deltagerne så nemt som muligt kan deltage. Vi forventer alt er på plads sommeren 2024, men hvis du har atrieflimren og kunne tænke dig at høre mere så har du muligheden for at skrive dig op - så vil vi kontakte dig når der er nyt. Tak fordi du viser interesse for studiet.",
    videoLink: "https://www.youtube.com/embed/APM2WqH-fdM?si=6l-wtuFPucT2oDzk",
    support: "Projektet er støttet af"
  },

  buttons: {
    documents: "Læs mere",
    signup: "Skriv dig op",
    video: "Se video beskrivelse"
  },

  signup: {
    infoText:
      "VIR-SAAF starter sommeren 2024 - hvis du ønsker at høre mere om studiet så kan du skrive dig op her og vi vil opdatere dig når vi går i gang eller når der er nyt omkring studiet",
    submit: "Indsend opskrivning",
    done: "Opskrivning modtaget. Du vil høre fra os.",
    btnSubmitted: "Opskrivning indsendt"
  }
}
