import "./index.scss"

import Button from "../Button"
import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"
import HovedStadenLogo from "~/images/region-hovedstaden.svg"
import ChecklistLogo from "~/images/checklist.svg"
import { SurveyProgressBullets } from "../SurveyProgressBullets"
import { useContext } from "react"
import { SurveyContext } from "../Main/SurveyContext"
import {
  LandingPageText,
  SurveyCompletionEstimates
} from "~/const/globalStrings"

export default function About({
  onStart,
  surveyIterationId
}: {
  onStart: VoidFunction
  surveyIterationId: string
}): JSX.Element {
  const context = useContext(SurveyContext)
  const estimatedCompletionTime =
    context &&
    Object.keys(context.status).reduce(function (prev, surveyId) {
      const isFinished = context.status[surveyId]
      return isFinished
        ? prev
        : prev + +SurveyCompletionEstimates[surveyId].estimatedTimeToComplete
    }, 0)

  return (
    <div className="About">
      <div className="about-text">
        <img src={HovedStadenLogo} alt="Region Hovedstaden" />

        <HTMLTagRenderer
          string={LandingPageText[surveyIterationId]["text_1"]}
        />
        <SurveyProgressBullets />
        <HTMLTagRenderer
          string={LandingPageText[surveyIterationId]["text_2"]}
        />
      </div>
      <div className="about-action">
        <img src={ChecklistLogo} alt="Book Logo" className="hidden lg:block" />
        <p>
          {estimatedCompletionTime
            ? `Estimeret tid ${estimatedCompletionTime} min.`
            : " "}
        </p>
        <Button type="submit" variant="basic" onClick={onStart}>
          {"Start her ->"}
        </Button>
      </div>
    </div>
  )
}
