import Arrow from "../Arrow"
import { authenticityToken } from "../../util/authenticityTokenHelper"
import Button from "../Button"
import ChecklistCompleteLogo from "~/images/checklistComplete.svg"
import HovedStadenLogo from "~/images/region-hovedstaden.svg"
import { IndexPageText } from "~/const/globalStrings"
import { Question } from "~/types/questionTypes"
import SurveyStepHeader from "../SurveyStepHeader"
import { SignUpInput } from "../SurveyStep/SignUpStep"
import { useState } from "react"
import { submitSignup } from "~/util/submitSignup"

export interface SignUpProps extends React.HTMLAttributes<HTMLDivElement> {
  exit: () => void
}

export default function SignUp({ exit }: SignUpProps): JSX.Element {
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [submitBtnText, setSubmitBtnText] = useState(
    IndexPageText.signup.submit
  )
  const [values, setValues] = useState({
    // eslint-disable-next-line camelcase
    authenticity_token: authenticityToken(),
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    region: ""
  })

  const updateFields = (newData: Record<string, string>) => {
    setValues((prevData) => ({ ...prevData, ...newData }))
  }

  const question: Question = {
    branchingLogic: "",
    fieldLabel: "",
    fieldName: "",
    fieldType: "signup",
    sectionHeader: IndexPageText.signup.infoText,
    fieldNote: "",
    formName: ""
  }

  const finished = (
    <div className="flex flex-col items-center lg:items-start">
      <img src={ChecklistCompleteLogo} alt="Book Logo" className="w-80 my-4" />
      <p>{IndexPageText.signup.done}</p>
    </div>
  )

  const survey = <SignUpInput values={values} changeValues={updateFields} />

  const [content, setContent] = useState(survey)

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let success = true
    await submitSignup(values).catch(() => {
      alert("Something went wrong. Please try again.")
      success = false
    })
    if (success) {
      setSubmitBtnText(IndexPageText.signup.btnSubmitted)
      setSubmitDisabled(true)
      setContent(finished)
    }
  }

  return (
    <div className="SurveyComponent" data-testid="signup">
      <form method="post" onSubmit={onSubmitHandler} name="survey">
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken()}
        />
        <div className="question-responsive">
          <SurveyStepHeader question={question} />
          <div className={`question-container grow`}>
            <img
              src={HovedStadenLogo}
              alt="Region Hovedstaden"
              className="hidden lg:block self-end"
            />
            <div className="question-group">{content}</div>
            <div className="button-group">
              <Button type="button" variant="secondary" onClick={exit}>
                <Arrow direction="left" />
              </Button>
              <Button type="submit" variant="basic" disabled={submitDisabled}>
                {submitBtnText}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
