import LandingFront from "../LandingFront"
import SignUp from "../SignUp"
import { useMultiPage } from "../../util/useMultiPage"

export interface LandingProps {
  files: { [key: string]: string[] }
}

export default function Landing({ files }: LandingProps): JSX.Element {
  const allPages: Array<JSX.Element> = [
    <LandingFront signUp={() => nextPage()} files={files} />,
    <SignUp exit={() => previousPage()} />
  ]

  const { currentPage, nextPage, previousPage } = useMultiPage(allPages)

  return <div className="main">{currentPage}</div>
}
