import { useContext, useState } from "react"
import { submitSurvey } from "../../util/submitSurvey"

import { Question } from "../../types/questionTypes"
import { authenticityToken } from "../../util/authenticityTokenHelper"
import { shouldIncludeBranchQuestion } from "../../util/shouldIncludeBranchQuestion"
import useMultiStepForm from "../../util/useMultiStepForm"
import SurveyStep from "../SurveyStep"
import HovedStadenLogo from "~/images/region-hovedstaden.svg"
import Arrow from "../Arrow"
import Button from "../Button"
import { filterAndFillDateQuestions } from "~/util/filterAndFillDateQuestions"
import SurveyStepHeader from "../SurveyStepHeader"
import { SurveyContext } from "../Main/SurveyContext"
import { SurveyCompletionEstimates } from "~/const/globalStrings"
import moment from "moment-timezone"

const PERMITTED_FIELD_TYPES = [
  "text",
  "radio",
  "descriptive",
  "notes",
  "slider",
  "explainer"
]

export interface Data {
  [key: string]: string
}

export interface SurveyComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  onSurveySubmit: () => void
  previousPage: () => void
  surveyMetadata: Array<Question>
}

export default function SurveyComponent({
  name,
  onSurveySubmit,
  previousPage,
  surveyMetadata,
  ...rest
}: SurveyComponentProps): JSX.Element {
  const [token] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get("token") || ""
  })
  const [submitDisabled, setsubmitDisabled] = useState(false)
  const [noDateQuestionsSurvey, dateAnswers] =
    filterAndFillDateQuestions(surveyMetadata)

  const context = useContext(SurveyContext)

  const [data, setData] = useState({
    // eslint-disable-next-line camelcase
    authenticity_token: authenticityToken(),
    token: token,
    // eslint-disable-next-line camelcase
    survey_name: name,
    ...dateAnswers
  })

  const updateFields = (newData: Data) => {
    setData((prevData) => ({ ...prevData, ...newData }))
  }

  const explainerMetaData: Question = {
    branchingLogic: "",
    fieldLabel: SurveyCompletionEstimates[name].name,
    fieldName: name,
    fieldType: "explainer",
    sectionHeader: "",
    fieldNote: "",
    formName: name,
    fieldAnnotation: ""
  }

  const filteredSurvey = [explainerMetaData].concat(
    noDateQuestionsSurvey.filter(
      (question) =>
        PERMITTED_FIELD_TYPES.includes(question.fieldType) &&
        shouldIncludeBranchQuestion(data, question) &&
        question.fieldAnnotation !== "auto_timestamp" &&
        question.fieldName !== "ehlq_forklar"
    )
  )

  const surveySteps: Array<JSX.Element> = filteredSurvey.map(
    (question: Question, index: number) => {
      return (
        <SurveyStep
          key={index}
          data={data}
          question={question}
          updateFields={updateFields}
        />
      )
    }
  )

  const {
    currentStepIndex,
    currentStep,
    isFirstStep,
    previousStep,
    nextStep,
    isLastStep
  } = useMultiStepForm(surveySteps)

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setsubmitDisabled(true)

    if (!isLastStep) {
      nextStep()
      setsubmitDisabled(false)
    } else {
      const autoTimestampQuestion = noDateQuestionsSurvey.find(
        (question) => question.fieldAnnotation === "auto_timestamp"
      )
      const timestamp = autoTimestampQuestion
        ? {
            [autoTimestampQuestion.fieldName]: moment()
              .tz("Europe/Copenhagen")
              .format()
          }
        : {}

      setData((prevData) => {
        const dataWithTimestamp = { ...prevData, ...timestamp }
        submitSurvey(dataWithTimestamp)
          .then(() => {
            context?.completeSurvey(name)
            onSurveySubmit()
          })
          .catch(() => {
            alert("Something went wrong. Please try again.")
          })
          .finally(() => {
            setsubmitDisabled(false)
          })

        return dataWithTimestamp
      })
    }
  }
  const currentQuestion = filteredSurvey[currentStepIndex]

  return (
    <div {...rest} className="SurveyComponent">
      <form method="post" onSubmit={onSubmitHandler} name="survey">
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken()}
        />
        <div className="question-responsive">
          <SurveyStepHeader question={currentQuestion} />
          <div className={`question-container grow`}>
            <img
              src={HovedStadenLogo}
              alt="Region Hovedstaden"
              className="hidden lg:block self-end"
            />
            <div className="question-group">{currentStep}</div>
            <div className="button-group">
              <Button
                type="button"
                variant="secondary"
                onClick={isFirstStep ? previousPage : previousStep}
              >
                <Arrow direction="left" />
              </Button>
              <Button type="submit" variant="basic" disabled={submitDisabled}>
                {isFirstStep ? (
                  <span>
                    Til første spørgsmål <Arrow direction="right" />
                  </span>
                ) : isLastStep ? (
                  "Indsend besvarelse"
                ) : (
                  <span>
                    Næste spørgsmål <Arrow direction="right" />
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
