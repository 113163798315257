import { HTMLAttributes } from "react"
import arrowLeftBlack from "./arrowLeftBlack.svg"
import arrowRightWhite from "./arrowRightWhite.svg"

export type ArrowProps = HTMLAttributes<HTMLDivElement> & {
  direction: "left" | "right"
}

export default function Arrow({ direction }: ArrowProps) {
  let arrowIcon
  switch (direction) {
    case "left": {
      arrowIcon = arrowLeftBlack
      break
    }
    default: {
      arrowIcon = arrowRightWhite
      break
    }
  }

  return <img className="arrow" alt={"Arrow " + direction} src={arrowIcon} />
}
