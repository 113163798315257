import DOMPurify from "dompurify"
import { HTMLProps } from "react"

export type HTMLTagRendererProps = {
  string: string
} & HTMLProps<HTMLDivElement>

const allowedTags: string[] = ["b", "em", "strong", "i", "br"]

export const HTMLTagRenderer = ({
  string,
  ...restProps
}: HTMLTagRendererProps) => {
  const cleanHTML = DOMPurify.sanitize(string, { ALLOWED_TAGS: allowedTags })

  return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} {...restProps} />
}
