import { useContext } from "react"
import { SurveyContext } from "../Main/SurveyContext"
import "./index.scss"

function SurveyProgressIndicator(): JSX.Element {
  const context = useContext(SurveyContext)
  if (!context) return <></>

  const total = Object.keys(context.status).length
  const current = Object.keys(context.status).reduce(function (prev, surveyId) {
    const isFinished = context.status[surveyId]
    return !isFinished ? prev : prev + 1
  }, 0)

  return (
    <div
      className="survey-progress-indicator"
      data-testid="survey-progress-indicator"
    >
      <div className="progress-bar">
        {[...Array(total)].map((_, index) => (
          <div
            key={index}
            className={`step-chunk ${index < current ? "completed" : ""}`}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default SurveyProgressIndicator
