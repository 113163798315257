import { Question } from "../../types/questionTypes"
import { useMultiPage } from "../../util/useMultiPage"
import About from "../About"
import SurveyComponent from "../SurveyComponent"
import SurveyFinisher from "../SurveyFinisher"
import "./index.scss"
import { SurveyContextProvider } from "./SurveyContext"
export interface Survey {
  name: string
  metadata: Array<Question>
}

interface MainComponentProps {
  surveyId: string
  surveys: Array<Survey>
}

export default function Main({
  surveyId,
  surveys
}: MainComponentProps): JSX.Element {
  const allPages: Array<JSX.Element> = [
    surveys.length ? (
      <About onStart={() => nextPage()} surveyIterationId={surveyId} /> // Landing page
    ) : (
      <SurveyFinisher isFinished={true} /> // No surveys left to complete
    )
  ]

  surveys.forEach((survey, index) => {
    allPages.push(
      <SurveyComponent
        key={index}
        name={survey.name}
        onSurveySubmit={() => nextPage()}
        previousPage={() => previousPage()}
        surveyMetadata={survey.metadata}
      />
    )
    if (index + 1 === surveys.length)
      allPages.push(<SurveyFinisher isFinished={true} />)
    else
      allPages.push(
        <SurveyFinisher isFinished={false} onClick={() => nextPage()} />
      )
  })

  const { currentPage, nextPage, previousPage } = useMultiPage(allPages)

  return (
    <SurveyContextProvider
      surveyIterationId={surveyId}
      surveys={surveys.map((survey) => survey.name)}
    >
      <div className="main">{currentPage}</div>
    </SurveyContextProvider>
  )
}
