import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"
import BookLogo from "~/images/book.svg"

export interface SurveyExplainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  text: string
}
export default function SurveyExplainer(
  props: SurveyExplainerProps
): JSX.Element {
  return (
    <div className="SurveyExplainer">
      <img src={BookLogo} alt="Book Logo" className="mb-8 w-28" />
      <HTMLTagRenderer string={props.text} className="mb-4" />
    </div>
  )
}
