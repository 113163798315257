import { ReactElement, useState } from "react"

export type UseMultiStepForm = {
  currentStep: ReactElement
  currentStepIndex: number
  isFirstStep: boolean
  isLastStep: boolean
  nextStep: () => void
  previousStep: () => void
  steps: ReactElement[]
}

export default function useMultiStepForm(
  steps: ReactElement[]
): UseMultiStepForm {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const currentStep = steps[currentStepIndex]

  const nextStep = () => {
    if (currentStepIndex === steps.length - 1) {
      return
    }
    setCurrentStepIndex(currentStepIndex + 1)
  }

  const previousStep = () => {
    if (currentStepIndex === 0) {
      return
    }
    setCurrentStepIndex(currentStepIndex - 1)
  }

  return {
    currentStep,
    currentStepIndex,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    nextStep,
    previousStep,
    steps
  }
}
