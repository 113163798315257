import { Data } from "~/components/SurveyComponent"
import { RadioQuestion } from "~/types/questionTypes"
import { redcapChoicesToRadioOptions } from "~/util/redcapChoicesToRadioOptions"
import RadioButtonIcon from "~/images/radioButtonIcon.svg"
import RadioButton from "~/components/RadioButton"

type RadioButtonGroupProps = {
  data: Data
  question: RadioQuestion
  updateFields: (newData: Data) => void
}

export const RadioButtonGroup = (props: RadioButtonGroupProps): JSX.Element => {
  const {
    question: { fieldName, selectChoicesOrCalculations },
    data,
    updateFields
  } = props
  data[fieldName] = data[fieldName] || ""

  const optionsWithValues = redcapChoicesToRadioOptions(
    selectChoicesOrCalculations
  )

  return (
    <>
      <div className="flex flex-row gap-2 mb-8">
        <img src={RadioButtonIcon} alt="Radio Icon" />
        <p>Vælg én af følgende</p>
      </div>
      {optionsWithValues.map(({ label, value }) => (
        <div key={fieldName + value} className="radio-option">
          <RadioButton
            required
            checked={data[fieldName] === value}
            key={fieldName + value}
            name={fieldName}
            label={label}
            value={value}
            onChange={(event) => {
              updateFields({ [fieldName]: event.target.value })
            }}
          />
        </div>
      ))}
    </>
  )
}
