import { useContext } from "react"
import { SurveyCompletionEstimates } from "~/const/globalStrings"
import { SurveyContext } from "../Main/SurveyContext"
import "./index.scss"
import Bullet from "~/images/bullet.svg"
import BulletCurrent from "~/images/bulletCurrent.svg"
import CheckmarkValid from "~/images/checkmark-valid.svg"

export const SurveyProgressBullets = (): JSX.Element => {
  const context = useContext(SurveyContext)
  if (!context) return <></>

  return (
    <div className="relative">
      {Object.keys(context.status).map((surveyId, index) => {
        const surveyEstimate = SurveyCompletionEstimates[surveyId]
        const isFinished = context.status[surveyId]
        const isCurrent = context.currentSurvey === surveyId
        return (
          <div
            key={index}
            className={`Bullet ${isFinished ? "isFinished" : ""} ${
              isCurrent ? "isCurrent" : ""
            }`}
          >
            <div className="Bullet--container">
              <img
                src={
                  isFinished
                    ? CheckmarkValid
                    : isCurrent
                      ? BulletCurrent
                      : Bullet
                }
                height={isFinished ? "20px" : "10px"}
                width={isFinished ? "20px" : "10px"}
                alt="bullet"
              />
            </div>
            <p
              className={`text-black leading-4 flex items-center ${
                isFinished ? "line-through" : ""
              } ${isFinished || isCurrent ? "font-bold" : ""}`}
            >
              {`${surveyEstimate.name} (ca. ${surveyEstimate.estimatedTimeToComplete} min.)`}
            </p>
          </div>
        )
      })}
    </div>
  )
}
