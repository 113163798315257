import { HeadersByFieldType } from "~/const/globalStrings"
import { Question } from "~/types/questionTypes"
import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"
import SurveyProgressIndicator from "../SurveyProgressIndicator"

export interface SurveyStepHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  question: Question
}

export default function SurveyStepHeader({
  question
}: SurveyStepHeaderProps): JSX.Element {
  const isDescriptiveQuestion = question.fieldType === "descriptive"
  return (
    <div className="SurveyStepHeader">
      <SurveyProgressIndicator />
      <div className="SurveyStepHeader--container">
        <div className="question-title">
          <p
            className={`pb-6 text-lg ${
              isDescriptiveQuestion ? "" : "hidden lg:block"
            }`}
          >
            {HeadersByFieldType[question.fieldType]}
          </p>
          {question?.sectionHeader ? (
            <HTMLTagRenderer
              className="section-header"
              string={question.sectionHeader}
            />
          ) : (
            ""
          )}
          {isDescriptiveQuestion ? (
            <></>
          ) : (
            <HTMLTagRenderer string={question?.fieldLabel} />
          )}
        </div>
      </div>
    </div>
  )
}
