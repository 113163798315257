import {
  Question,
  RadioQuestion,
  SliderQuestion,
  TextQuestion
} from "../../types/questionTypes"
import { Data } from "../SurveyComponent"
import SurveyExplainer from "../SurveyExplainer"
import { Explainers } from "~/const/globalStrings"
import { TextInput } from "./TextInput"
import { TextareaInput } from "./TextareaInput"
import { RadioButtonGroup } from "./RadioButtonGroup"
import { SliderInput } from "./SliderInput"
import { useEffect, useRef } from "react"
import QuestionIcon from "~/images/questionIcon.svg"
import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"

export interface SurveyStepProps {
  data: Data
  question: Question
  updateFields: (newData: Data) => void
}

export default function SurveyStep({
  data,
  question,
  updateFields
}: SurveyStepProps): JSX.Element {
  const scrollRef = useRef<null | HTMLDivElement>(null)

  useEffect(
    () =>
      scrollRef?.current?.parentElement?.parentElement?.scroll({
        top: 0,
        behavior: "smooth"
      }),
    []
  )

  const renderQuestion = () => {
    switch (question.fieldType) {
      case "explainer":
        return <SurveyExplainer text={Explainers[question.formName]} />
      case "radio":
        return (
          <RadioButtonGroup
            data={data}
            question={question as RadioQuestion}
            updateFields={updateFields}
          />
        )
      case "text":
        return (
          <TextInput
            data={data}
            question={question as TextQuestion}
            updateFields={updateFields}
          />
        )
      case "notes":
        return (
          <TextareaInput
            data={data}
            question={question as TextQuestion}
            updateFields={updateFields}
          />
        )
      case "slider":
        return (
          <SliderInput
            data={data}
            question={question as SliderQuestion}
            updateFields={updateFields}
          />
        )
      case "descriptive":
        return (
          <>
            <div className="flex flex-row gap-2 mb-8">
              <img src={QuestionIcon} alt="Question Icon" />
              <p>
                Den næste række spørgsmål vil omhandle det overordnede
                spørgsmål, fortsættes på næste side.
              </p>
            </div>
            <div className="descriptive-question">
              <HTMLTagRenderer string={question?.fieldLabel} />
            </div>
          </>
        )
      default:
        return <div></div>
    }
  }

  return (
    <>
      <div ref={scrollRef} />
      {renderQuestion()}
    </>
  )
}
