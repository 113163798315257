import axios from "axios"
import { Data } from "../components/SurveyComponent"

export async function submitSignup(data: Data) {
  return axios
    .post("/index", data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => response.data)
}
