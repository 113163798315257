import "./index.scss"

import HovedStadenLogo from "~/images/region-hovedstaden.svg"
import Button from "../Button"
import Arrow from "../Arrow"
import { SurveyProgressBullets } from "../SurveyProgressBullets"
import { useContext } from "react"
import { SurveyContext } from "../Main/SurveyContext"
import { SurveyCompletionEstimates } from "~/const/globalStrings"
import ChecklistLogo from "~/images/checklist.svg"
import ChecklistCompleteLogo from "~/images/checklistComplete.svg"

export default function SurveyFinisher({
  onClick,
  isFinished
}: {
  onClick?: VoidFunction
  isFinished: boolean
}): JSX.Element {
  const goToNextSurvey =
    "Tak for fuldendt spørgeskema du kan vælge at gå videre til næste skema herunder."
  const finishedAllSurveys =
    "Tak for fuldendt spørgeskema. Du har nu besvaret alle spørgeskemaer."

  const context = useContext(SurveyContext)
  const estimatedCompletionTime =
    context &&
    Object.keys(context.status).reduce(function (prev, surveyId) {
      const isFinished = context.status[surveyId]
      return isFinished
        ? prev
        : prev + +SurveyCompletionEstimates[surveyId].estimatedTimeToComplete
    }, 0)

  return (
    <div className="SurveyFinisher">
      <div className="SurveyFinisher__text">
        <img src={HovedStadenLogo} alt="Region Hovedstaden" />
        <p>Færdiggjort spørgeskema!</p>
        <p>{isFinished ? finishedAllSurveys : goToNextSurvey}</p>
        <SurveyProgressBullets />
      </div>
      <div className="SurveyFinisher__action">
        {isFinished ? (
          <>
            <img
              src={ChecklistCompleteLogo}
              alt="Book Logo"
              className="hidden lg:block"
            />
            <p>
              Tak for din besvarelse. Du vil inden længe modtage en pakke med
              posten med de to måleinstrumenter. Du vil få besked når pakken er
              blevet sendt.
            </p>
          </>
        ) : (
          <>
            <img
              src={ChecklistLogo}
              alt="Book Logo"
              className="hidden lg:block"
            />
            <p>
              {estimatedCompletionTime
                ? `Estimeret tid ${estimatedCompletionTime} min.`
                : " "}
            </p>
            <Button type="submit" variant="basic" onClick={onClick}>
              <span>
                Fortsæt <Arrow direction="right" />
              </span>
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
