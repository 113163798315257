import { Data } from "../components/SurveyComponent"
import { Question } from "../types/questionTypes"
import { parseBranchingLogic } from "./parseBranchingLogic"

export function shouldIncludeBranchQuestion(
  data: Data,
  question: Question
): boolean {
  const branchingLogic = question.branchingLogic
  if (branchingLogic === "") return true

  const { variableName, operator, rightValue } =
    parseBranchingLogic(branchingLogic)
  if (variableName === null) return false

  const value = data[variableName]

  switch (operator) {
    case "<>":
      return value != rightValue
    case "=":
      return value == rightValue
    default:
      return false
  }
}
