import axios from "axios"
import { Data } from "../components/SurveyComponent"

export function submitSurvey(data: Data) {
  return axios
    .post("/", data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => response.data)
}
