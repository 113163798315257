import { Data } from "~/components/SurveyComponent"
import { TextQuestion } from "~/types/questionTypes"
import textAreaIcon from "~/images/textAreaIcon.svg"

type TextareaInputProps = {
  data: Data
  question: TextQuestion
  updateFields: (newData: Data) => void
}

export const TextareaInput = (props: TextareaInputProps): JSX.Element => {
  const {
    question: { fieldName },
    data,
    updateFields
  } = props
  data[fieldName] = data[fieldName] || ""

  return (
    <>
      <div className="flex flex-row gap-2 mb-2">
        <img src={textAreaIcon} alt="TextArea Icon" />
        <p>Beskriv</p>
      </div>
      <textarea
        className="TextareaInput"
        autoFocus
        name={fieldName}
        value={data[fieldName]}
        onChange={(event) => {
          updateFields({ [fieldName]: event.target.value })
        }}
      />
    </>
  )
}
